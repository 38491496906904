import styles from './blogs.module.css';
import { Link } from 'react-router-dom';

export default function Blogs() {

return(<>
    <div className={styles["header"]}>
        <div className={styles["home-head"]}>
            <div>
            <p><img src="./images/back-arrow-logo.svg" alt=""/> <Link to="/"><span>Back</span></Link></p>
            </div>
            <div>
            <Link to="/"><img src="./images/rto-image.svg" alt=""/></Link>
            </div>
        </div>
    </div>
    <div className={styles['blog-content']}>
    <h4>How to Instantly Check Bike Insurance Validity on Parivahan:</h4> 
    <div className={styles['rto-image']}>
        <img src="./images/insurance-update.svg" alt="" style={{marginBottom: "10"}}/>
    </div>
    <p>Due to the surge in demand for vehicles among the Indian public, the Ministry of Road Transport and Highways launched an online web portal called Parivahan. This platform keeps records and tracks all vehicles running across the country by providing a unique registration number to each vehicle. Parivahan offers numerous services such as driving license issuance, permit issuance, fitness certificates, road tax payments, and many other related services. The main aim behind developing this Parivahan web portal is to reduce the burden of paperwork and in-person visits to transport offices.
    Benefits Offered by Parivahan</p>
    <br/>
    <p><b>The Parivahan web portal and mobile app have digitized the process of managing vehicle-related documents and services, offering several benefits:</b></p>
    <br/>
    <p>Digital Documents: Hard copy documents are no longer needed. Digitized documents can be presented to authorities while traveling.</p>
    <p><h6>Driving License Enrollment:</h6> Users can enroll for a new driving license and follow instructions provided in the application. A passing mark is required to crack the driving license examination.</p>
    <p><h6>Vehicle Specifications:</h6> The details of any particular vehicle can be checked by entering the RC (Registration Number). This facility is available to both registered and unregistered users.</p>
    <p><h6>Nearest RTO Office:</h6> The Parivahan app helps find the nearest RTO office in a specified region.</p>
    <p><h6>Driving Test Booking:</h6> Users can book a driving test using the app.</p>
    <p><h6>Emergency Numbers:</h6> The app contains emergency numbers that can be dialed in case of accidents or mishaps.</p>
    <p><h6>Check Challans:</h6> Users can check challans by entering their RC (Registration Certificate) or DL (Driving License) numbers.</p>
    <p><h6>Fancy Number Plate Booking:</h6> The app allows booking of fancy number plates.</p>
    <p>Steps to Check Bike Insurance Validity on Parivahan</p>
    <br/>
    <p>The Parivahan website, administered centrally by the Ministry of Road Transport & Highways, provides a platform for Indian citizens to store and access information on all the vehicles in the country. To check the status of two-wheeler insurance on the Parivahan app, follow these steps:</p>
    <p><b>Access the Official Website: </b>Visit the official Parivahan website.</p>
    <p><b>Information Services: </b>Click on the “Information services” tab on the menu.</p>
    <p><b>Know Your Vehicle: </b>In the drop-down menu, search for and select the “Know Your Vehicle” tab.</p>
    <p><b>Vahan NR e-Services: </b>The Vahan NR e-services site will open in a new browser window.</p>
    <p><b>Log In: </b>Enter your registered mobile number to log in, or create a new account if you do not have one.</p>
    <p><b>Verification Code: </b>Enter the verification code and your vehicle’s registration number after the login process.</p>
    <p><b>Search Vehicle: </b>Choose the “Search Vehicle” option.</p>
    <p><b>View Details: </b>All the details of the bike, including the expiry date of the policy, will be displayed on the screen.</p>
    <br/>
    <h5>FAQs - Frequently Asked Questions</h5>
    <b>Q1. How long does a bike insurance policy remain valid?</b>
    <p>Most bike insurance policies are valid for one year. However, for a new bike, the insurance plan is valid for three years as per the Motor Vehicle Act of 1988.</p>
    <br/>
    <b>Q2. Can anyone get a bike insurance expiry date online even in case of a lost vehicle’s registration number?</b>
    <p>Yes, the expiry date of the bike insurance policy can be checked even without the bike’s registration number. Visit the insurer’s website or mobile app and access the account using the registered mobile number. Alternatively, check your email inbox for the policy document featuring the expiry date.</p>
    <br/>
    <b>Q3. Is it possible to renew the bike insurance online after the expiry date?</b>
    <p>Yes, bike insurance can be renewed online even after its expiry date. However, the insurer may require an inspection of the bike before renewing the policy.</p>
    <br/>
    <b>Q4. When should a bike insurance renewal be done?</b>
    <p>It is advisable to renew the bike insurance before the expiry date for continuous coverage. Renew the policy at least 5 to 10 days before the expiry date.</p>
    </div>
</>)
}