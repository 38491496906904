import React, { useState } from 'react';
import api, { checkMobile, sendOtp, otpVerification } from '../../Base/Config';
import tokenapi, { profileDetails } from '../../Base/TokenConfig';
import { setSession } from '../../Utils/Common';
import { decrypt } from '../../Utils/DecryptFunction';
import Message from '../../Components/Message/Message';
import styles from '../login.module.css';

export default function SignIn(props) {
    localStorage.removeItem("mobileNumber");
    const [mobileNumber, setMobileNumber] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage] = useState('');
    const [decryptData, setDecryptData] = useState('');
    const [token, setToken] = useState('');
    const [timer, setTimer] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkMobileNumber = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(checkMobile, {
            mobileNumber: mobileNumber
        })
        .then(res => {
            setLoading(false);
            const result = decrypt(res.data.data, res.data.txn, "checkMobile");
            setDecryptData(result);
            if(res.data.code === 200){
                if(result.verificationToken) {
                    setSession(result.verificationToken, JSON.stringify(result));
                    window.location.replace('/');
                } else {
                    otpSend();
                    setMessageClass('success');
                    setMessage(res.data.message);
                    setTimeout(() => {
                    }, 5000);
                }
            } else {
                localStorage.setItem("mobileNumber", mobileNumber);
                props.changeStepName('sign-up');
            }
        })
        .catch(error => { 
            console.log(error);
            setLoading(false);
            if(error.response.data.code === 404) {
                props.changeStepName('sign-up');
            } else{
                setMessageClass('error');
                setMessage("something went wrong! please try again");
                setTimeout(() => {
                }, 5000);
            }
        })
    }

    const otpSend = () => {
        setLoading(true);
        api.post(sendOtp, {
            mobileNumber: mobileNumber,
            rc_num: ""
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setOtp('');
                const result = decrypt(res.data.data, res.data.txn, "check-mobile");
                setToken(result.token);
                startTimer();
                setOtpSent(true);
                setMessageClass('success');
                setMessage('Otp sent successfully')
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage("OTP Sending Failed.")
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
            setMessageClass('error');
            setMessage("Something went wrong.")
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const startTimer = () => {
        setTimer(30);
        timerFunction(30);
    }

    const timerFunction = (value) => {
        if(value > 0){
            setTimeout(() => {
                setTimer(value - 1);
                timerFunction(value - 1);
            }, 1000)
        } else {
            // Do nothing
        }
    }

    const verifyOtp = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(otpVerification, {
            token: token,
            otp: otp,
            mobileNumber: decryptData.mobileNumber,
            name: decryptData.mParCitizenUser.mparCitizenUser.ctzDispName,
            email: decryptData.mParCitizenUser.mparCitizenUser.ctzEmail,
            mPin: decryptData.mParCitizenUser.mparCitizenUser.ctzMpin,
            confirmMPin: decryptData.mParCitizenUser.mparCitizenUser.ctzMpin,
            state: decryptData.mParCitizenUser.mparCitizenUser.ctzStateCd,
            deviceModel: '',
            deviceOsType: '',
            deviceOsVersion: '',
            deviceFcmToken: '',
            deviceId: ''
        })
        .then(res => {
            if(res.data.code === 200){
                getProfileDetail(res.data.token);
            } else {
                setLoading(false);
                setMessageClass('error');
                setMessage("Something went wrong!")
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
            setMessageClass('error');
            setMessage("Something went wrong! Please try again")
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const getProfileDetail = (token) => {
        tokenapi.get(profileDetails, {
            headers: {
                token: token
            }
        })
        .then((res) => {
            setLoading(false);
            const result = decrypt(res.data.data, res.data.txn);
            if(res.data.code === 200) {
                setSession(token, JSON.stringify(result));
                setMessageClass('success');
                setMessage("OTP successfully verified");
                setTimeout(() => {
                    setMessage('');
                    window.location.replace('/');
                }, 2000);
            } else {
                setMessageClass('error');
                setMessage("Something went wrong!")
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <h3 className={styles['heading']}>Sign In</h3>
        {
            otpSent
            ?<>
                <form onSubmit={verifyOtp}>
                    <input type="text" maxLength="10" minLength="10" placeholder="Enter phone number" defaultValue={mobileNumber} disabled/>
                    {/* <label>Enter OTP</label> */}
                    <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" required/>
                    <p className={styles['resend-otp']}>
                        {
                            timer > 0
                            ?<span style={{color:'#747474',cursor:'default'}}> Resend in {timer > 0 && `${timer}s`} </span>
                            :<>Didn't get OTP? <span style={{cursor:"pointer"}} onClick={otpSend}>Resend</span></>
                        }
                    </p>
                    <button type="submit" className="btn primary-button" disabled={loading}>{loading ? 'Wait ...' : 'Verify OTP'}</button>
                </form>
            </>
            :<>
                <form onSubmit={checkMobileNumber}>
                    <input type="text" maxLength="10" minLength="10" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Enter phone number" required/>
                    <button type="submit" className="btn primary-button" disabled={loading}>{loading ? 'Wait ...' : 'Send OTP'}</button>
                </form>
                <div className={styles['have-account']}>
                    <p>Don't have an account? <span onClick={() => props.changeStepName('sign-up')}>Sign up</span></p>
                </div>
            </>
        }
    </>)
}