import { useState } from "react";
import { Link } from "react-router-dom";
import tokenapi, { vehicleBasicDetails, vehicleCompleteDetails, verifyOtp } from '../Base/TokenConfig';
import api, {sendOtp} from '../Base/Config';
import Message from "../Components/Message/Message";
import Footer from "../Components/Footer/Footer";
import styles from './vehicle-detail.module.css';
import { decrypt } from "../Utils/DecryptFunction";
import { useNavigate } from "react-router-dom";


export default function VehicleDetail() {
    const [stepName, setStepName] = useState('enter-details');
    const [verifyModal, setVerifyModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage] = useState('');
    const [decryptData, setDecryptData] = useState('');
    const [loading, setLoading ] = useState(false);
    const [vehicleDecryptData, setVehicleDecryptData ] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [token, setToken ] = useState('');
    const [otp, setOtp] = useState('');

    const navigate = useNavigate();

    const basicVehicleDetails = () => {
        setLoading(true);
        tokenapi.post(vehicleBasicDetails, {
            vehicleNumber: vehicleNumber
        })
        .then(res => {
        setLoading(false);
            if(res.data.data) {
                const result = decrypt(res.data.data, res.data.txn, "fastag-Details");
                setDecryptData(result);
            }
            if(res.data.code === 200){
                setStepName('vehicle-detail')
                setMessageClass('success');
                setMessage("verify-successfully");
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            } else if(res.data.code === 401) {
                navigate('/signout');
            } else {
                setMessageClass('error');
                setMessage("Something went wrong! try again");
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong! try again");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })

    }

    const otpSend = () => {
        setLoading(true);
        api.post(sendOtp, {
            mobileNumber: mobileNumber,
            rc_num: vehicleNumber,
            
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                const result = decrypt(res.data.data, res.data.txn, "Send OTP");
                setToken(result.token);
                setMessageClass('success');
                setMessage('Otp sent successfully');
            } else {
                setMessageClass('error');
                setMessage("OTP Sending Failed.");
            }
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
            setMessageClass('error');
            setMessage("Something went wrong.")
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const otpVerify = () => {
        setLoading(true);
        tokenapi.post(verifyOtp, {
            vehicleNumber: vehicleNumber,
            mobileNumber: mobileNumber,
            otp: otp,
            token: token
        })
        .then(res => {
            setLoading(false);
            decrypt(res.data.data, res.data.txn, "Verify Otp");
            if(res.data.code === 200){
                setConfirmModal(false);
                setMobileNumber('');
                setOtp('');
                vehicleFullDetails();
                setStepName("complete-vehicle-detail");
                setMessageClass('success');
                setMessage("OTP successfully verified");
            } else {
                setMessageClass('error');
                setMessage("Something went wrong!")
                
            } setTimeout(() => {
                setMessage('');
            }, 5000);
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
            setMessageClass('error');
            setMessage("Something went wrong! Please try again");
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const vehicleFullDetails = () => {
        setLoading(false);
        tokenapi.post(vehicleCompleteDetails, {
            vehicleNumber: vehicleNumber
        })
        .then((res) => {
            setLoading(false);
            const result = decrypt(res.data.data, res.data.txn, "Full details");
            setVehicleDecryptData(result);
            if(res.data.code=== 200) {
                setStepName('complete-vehicle-detail');
                setMessageClass('success');
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            } else {
                setMessageClass("error");
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000)
            }
        })
        .catch(error => {
            console.log(error);
            setMessageClass('error');
            setMessage("Something went wrong! Please try again");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }

    const fullDetailFunction = () => {
        setConfirmModal(true);
        setVerifyModal(false);
        otpSend();
    }

    const confirmOtp = () => {
        otpVerify();
        
    }
    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {verifyModal && <div className={styles["vehicle-container"]}>
            <div className={styles["vehicle-background"]}></div> 
            <div className={styles["vehicle-page"]}>
                <div className={styles["verify-and-cancel"]}>
                    <div>Verify</div>
                    <img src="./images/cancel-logo.svg" alt="" onClick={() =>setVerifyModal(false)}/>
                </div>
                <input type="text" value={mobileNumber} placeholder="Enter Your Phone Number" onChange={(e) => setMobileNumber(e.target.value)}/>
                <button className="btn primary-button" onClick={() => fullDetailFunction()}>Send OTP</button>
            </div>
        </div>}
        {confirmModal && <div className={styles["vehicle-container"]}>
            <div className={styles["vehicle-background"]}></div> 
            <div className={styles["vehicle-page"]}>
                <div className={styles["verify-and-cancel"]}>
                    <div>Verify</div>
                    <img src="./images/cancel-logo.svg" alt="" onClick={() =>setConfirmModal(false)}/>
                </div>
                <input type="text" placeholder="Enter Your Phone Number" defaultValue={mobileNumber} disabled />
                <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP"/>
                <button className="btn primary-button" onClick={() => confirmOtp()}>Verify & Confirm</button>
            </div>
        </div>}
        {stepName === "enter-details" && <>
            <div className={styles["vehicle-flow-header"]}>
                <div className={styles["header"]}>
                    <p className={styles["back-button"]}><img src="./images/back-arrow-logo.svg" alt="" /><Link to="/"><span>Back</span></Link></p>
                    <div className={styles["header-image"]}>
                        <img src= "./images/vehicle-full-detail-image.svg" alt=""/>
                        <p>Get full details of any vehicle</p>
                    </div>
                </div>
            </div>
            <div className={styles["input-detail"]}>
                <h3 className={styles["enter-vehicle-details"]}>Enter Vehicle Details</h3> 
                <div className={styles["search-details"]}>
                    <input type="text" value={vehicleNumber}  onChange={(e) => setVehicleNumber(e.target.value)} placeholder="ex. HRXXN9090" />
                    {
                        vehicleNumber
                        ?
                        <>{loading 
                            ?<button className="btn primary-button">wait...</button>
                            :<button className="btn primary-button" onClick={() => basicVehicleDetails()}>Search</button>
                        }
                        </>
                        // <button className="btn primary-button" onClick={() => basicVehicleDetails()}>Search</button>
                        :<button>Search</button>
                    }
                </div>
            </div>
            
        </>}
        {stepName === 'vehicle-detail' && <>
            <div className={styles["history-header"]}>
                <p className={styles["back-option"]}><img src="./images/back-arrow-logo.svg" alt="" onClick={() =>setStepName('enter-details')}/> Back</p>
                <div className={styles["vehicle-complete-info"]}>
                    <div><img src="./images/vehicle-detail-image.svg" alt=""/></div>
                    <div>
                        <span>Vehicle Search history</span>
                        <p>{decryptData.data.regNo ?decryptData.data.regNo : "-" }</p>
                    </div>   
                </div>
            </div>
            <h3 className={styles["history"]}>Vehicle-Details</h3>
            <div style={{padding: "15px"}}>
                <div className={styles["vehicle-final-data"]}>
                    <p>Vehicle Number<span>{decryptData.data.regNo ?decryptData.data.regNo : "-" }</span></p>
                    <p>Owner Name<span>{decryptData.data.owner?decryptData.data.owner : '-'}</span></p>
                    <p>Registering Authority<span>{decryptData.data.regAuthority?decryptData.data.regAuthority :"-" }</span></p>
                    <p>Vehicle<span>{decryptData.data.model? decryptData.data.model:"-"}</span></p>
                    <div className={styles["blur-content"]}>
                        <div>
                            <img src="./images/full-detail-image.svg" alt=""/>
                            <p>Want to access full details of vehicle?</p>
                        </div>
                        <button className="btn primary-button" onClick={() => setVerifyModal(true)}>Get details</button>
                    </div>
                    {/* <div style={{backgroundImage: `url(${imageUrl})`}} > */}
                    <div style={{ filter: `blur(${'5px'})`, marginTop: "30px"}} >
                        <div className={styles["vehicle-blur-data"]}>
                            <p>Fuel type<span>P*T*O*</span></p>
                            <p>Emission Norm<span>B*A*A* S*A*E **</span></p>
                            <p>Vehicle Age<span>**</span></p>
                            <p>Vehicle Status<span>ACTIVE</span></p>
                            <p>Registration Details<span>18-****</span></p>
                            <p>Fitness Valid Upto<span>17-*-****</span></p>
                            <p>Tax valid Upto<span>LTT</span></p>
                            <p>Insurance Valid Upto<span>10-*-****</span></p>
                            <p>PUCC Valid Upto<span>23-*-****</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </>}
        {stepName === 'complete-vehicle-detail' && <>
            <div className={styles["history-header"]}>
                <p className={styles["back-option"]} onClick={() =>setStepName('enter-details')}><img src="./images/back-arrow-logo.svg" alt=""/> Back</p>
                <div className={styles["vehicle-complete-info"]}>
                    <div><img src="./images/vehicle-detail-image.svg" alt=""/></div>
                    <div>
                        <span>Vehicle Search history</span>
                        <p>{vehicleDecryptData?.data?.data?.vehicleNumber}</p>
                    </div>   
                </div>
            </div>
            <h3 className={styles["history"]}>Vehicle-Details</h3>
            <div style={{padding: "15px"}}>
                <div className={styles["vehicle-final-data"]}>
                    <p>Vehicle Number<span>{vehicleDecryptData?.data?.data?.vehicleNumber}</span></p>
                    <p>Owner Name<span>{vehicleDecryptData?.data?.data?.owner}</span></p>
                    <p>Registering Authority<span>{vehicleDecryptData?.data?.data?.regAuthority}</span></p>
                    <p>Vehicle<span>{vehicleDecryptData?.data?.data?.vehicleClass}</span></p>
                    <p>Vehicle Manufacturing Month Year<span>{vehicleDecryptData?.data?.data?.vehicleManufacturingMonthYear}</span></p>
                    <p>Emission Norm<span>{vehicleDecryptData?.data?.data?.normsType}</span></p>
                    <p>Color<span>{vehicleDecryptData?.data?.data?.vehicleColour}</span></p>
                    <p>Status<span>{vehicleDecryptData?.data?.data?.status}</span></p>
                    <p>Registration Details<span>{vehicleDecryptData?.data?.data?.regDate}</span></p>
                    <p>Chasis Number<span>{vehicleDecryptData?.data?.data?.chassis}</span></p>
                    <p>Engine Number<span>{vehicleDecryptData?.data?.data?.engine}</span></p>
                    <p>Insurance Valid Upto<span>{vehicleDecryptData?.data?.data?.vehicleInsuranceUpto}</span></p>
                    <p>RC Expiry<span>{vehicleDecryptData?.data?.data?.rcExpiryDate}</span></p>
                </div>
            </div>
        </>}
        <Footer/>
    </>)
}