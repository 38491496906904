import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeSession, removeAuthToken } from '../Utils/Common';
import styles from './signout.module.css';

const Signout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear authentication data (token and authToken from local storage)
        removeSession();
        removeAuthToken();
        
        // Redirect to the login page
        navigate('/login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles['signout-container']}>
            <h2>You have been signed out</h2>
            <p>Redirecting to login page...</p>
        </div>
    );
};

export default Signout;