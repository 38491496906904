import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

export const decrypt = (str, t, string) => {
    const result = dec(str, t);
    const jsonData = JSON.parse(result);
    if(string) console.log(string, jsonData);
    return jsonData;
}

const dec = (str, t) => {
    const key = keys(t) + "!~)#@*&^";
    const decodedStr = Buffer.from(str, 'base64').toString('utf8');
    const trimmedStr = decodedStr.trim();
    const decodedBytes = CryptoJS.enc.Base64.parse(trimmedStr);
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: decodedBytes },
        CryptoJS.enc.Utf8.parse(key),
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }
    );
    return CryptoJS.enc.Utf8.stringify(decrypted).trim();
};

const keys = (t) => {
    const a = _.take(t, 4).join('');
    const b = _.reverse(t.split('')).join('');
    const var10000 = _.take(b, 4).join('');
    return var10000 + _.reverse(a.split('')).join('');
};