import React, { useState } from 'react';
import api, {checkMobile, sendOtp, otpVerification } from '../../Base/Config';
import tokenapi, { profileDetails } from "../../Base/TokenConfig";
import { decrypt } from "../../Utils/DecryptFunction";
import { setSession, getAuthToken } from "../../Utils/Common";
import Message from '../../Components/Message/Message';
import styles from '../login.module.css';

export default function SignUp(props) {
    const [step, setStep] = useState(1);
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState();
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [state, setState] = useState('');
    const [email, setEmail] = useState('');
    const [mpin, setMpin] = useState('');
    const [confirmMpin, setConfirmMpin] = useState('');
    const [timer, setTimer] = useState(false);
    const [loading, setLoading] = useState(false);

    const newMobileNumber =  localStorage.getItem("mobileNumber");

    const checkMobileNumber = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(checkMobile, {
            mobileNumber: mobileNumber 
        })
        .then(res => {
            setLoading(false);
            decrypt(res.data.data, res.data.txn, "Check mobile");
            if(res.data.code === 200){
                setMessageClass('error');
                setMessage("You are already registered with us! please login ");
                setTimeout(() => {
                    setMessage("");
                }, 5000);
            } else {
                if (mpin !== confirmMpin){
                    setMessageClass('error');
                    setMessage("Mpin & ConfirmMpin should be same");
                    setTimeout(() => {
                        setMessage('');
                    }, 5000);
                } else {
                    otpSend();
                    setMessageClass('success');
                    setMessage("Mpin & ConfirmMpin are Verify");
                    setTimeout(() => {
                        setMessage('');
                    }, 5000);
                }
            }
        })
        .catch(error => { 
            console.log(error);
            setLoading(false);
            if(error.response.data.code === 404) {
                setStep('sign-up');
            } else{
                setMessageClass('error');
                setMessage("something went wrong! please try again");
                setTimeout(() => {
                }, 5000);
            }
        })
    }

    const otpSend = () => {
        setLoading(true);
        api.post(sendOtp, {
            mobileNumber: newMobileNumber? newMobileNumber :mobileNumber,
            rc_num: ""
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                getAuthToken();
                setStep(2);
                const result = decrypt(res.data.data, res.data.txn, "Send OTP");
                setToken(result.token);
                startTimer();
                setMessageClass('success');
                setMessage('OTP sent successfully.')
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessageClass('error');
                setMessage("OTP Sending Failed.")
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        })
    }

    const verifyOtp = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(otpVerification, {
            token: token,
            otp: otp,
            mobileNumber: newMobileNumber?newMobileNumber :mobileNumber,
            name: name,
            email: email,
            mPin: mpin,
            confirmMPin: confirmMpin,
            state: state,
            deviceModel: "",
            deviceOsType: "",
            deviceOsVersion: "",
            deviceFcmToken: "",
            deviceId: ""
        })
        .then(res => {
            setLoading(false);
            console.log(res);
            decrypt(res.data.data, res.data.txn, "verifyOtp");
            // setDecryptData(JSON.parse(result));
            if(res.data.code === 200){
                getProfileDetail(res.data.token);
                setMessageClass('success');
                setMessage("OTP successfully verified");
            }else {
                setMessageClass('error');
                setMessage("Something went wrong! Please try again")
            }
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            setMessageClass('error');
            setMessage("Something went wrong! Please try again")
            setTimeout(() => {
                setMessage('');
            }, 5000);
        })
    }

    const getProfileDetail = (token) => {
        tokenapi.get(profileDetails, {
            headers: {
                token: token
            }
        })
        .then((res) => {
            setLoading(false);
            const result = decrypt(res.data.data, res.data.txn, "Profile data");
            if(res.data.code === 200) {
                setSession(token, JSON.stringify(result));
                setMessageClass('success');
                setMessage("OTP successfully verified");
                localStorage.removeItem("mobileNumber");
                setTimeout(() => {
                    setMessage('');
                    window.location.replace('/');
                }, 2000);
            } else {
                setMessageClass('error');
                setMessage("Something went wrong!")
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
        })
    }

    const startTimer = () => {
        setTimer(30);
        timerFunction(30);
    }

    const timerFunction = (value) => {
        if(value > 0){
            setTimeout(() => {
                setTimer(value - 1);
                timerFunction(value - 1);
            }, 1000)
        } else {
            // Do nothing
        }
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <h3 className={styles['heading']}>Sign Up</h3>
        {step === 1 && <>
            <form onSubmit={checkMobileNumber}>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)}  placeholder="Enter name" required/>
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="Enter email" required/>
                {!newMobileNumber && <input type="text" maxLength="10" minLength="10" value={mobileNumber} placeholder="Enter phone number" onChange={(e) => setMobileNumber(e.target.value)} required/>}
                <input type="text" value={mpin} maxLength="6" minLength="6" onChange={(e) => setMpin(e.target.value)}  placeholder="Enter 6 Digit MPIN" required/>
                <input type="text" value={confirmMpin} maxLength="6" minLength="6" onChange={(e) => setConfirmMpin(e.target.value)}  placeholder="Confirm 6 Digit MPIN" required/>
                <select value={state} onChange={(e) => setState(e.target.value)} required>
                    <option value="">-- Select --</option>
                    <option value="AP">Andhra Pradesh</option>
                    <option value="AR">Arunachal Pradesh</option>
                    <option value="AS">Assam</option>
                    <option value="BR">Bihar</option>
                    <option value="CT">Chhattisgarh</option>
                    <option value="GA">Gujarat</option>
                    <option value="HR">Haryana</option>
                    <option value="HP">Himachal Pradesh</option>
                    <option value="JK">Jammu and Kashmir</option>
                    <option value="GA">Goa</option>
                    <option value="JH">Jharkhand</option>
                    <option value="KA">Karnataka</option>
                    <option value="KL">Kerala</option>
                    <option value="MP">Madhya Pradesh</option>
                    <option value="MH">Maharashtra</option>
                    <option value="MN">Manipur</option>
                    <option value="ML">Meghalaya</option>
                    <option value="MZ">Mizoram</option>
                    <option value="NL">Nagaland</option>
                    <option value="OR">Odisha</option>
                    <option value="PB">Punjab</option>
                    <option value="RJ">Rajasthan</option>
                    <option value="SK">Sikkim</option>
                    <option value="TN">Tamil Nadu</option>
                    <option value="TG">Telangana</option>
                    <option value="TR">Tripura</option>
                    <option value="UT">Uttarakhand</option>
                    <option value="UP">Uttar Pradesh</option>
                    <option value="WB">West Bengal</option>
                    <option value="AN">Andaman and Nicobar Islands</option>
                    <option value="CH">Chandigarh</option>
                    <option value="DN">Dadra and Nagar Haveli</option>
                    <option value="DD">Daman and Diu</option>
                    <option value="DL">Delhi</option>
                    <option value="LD">Lakshadweep</option>
                    <option value="PY">Puducherry</option>
                </select>
                <button type="submit" className="btn primary-button" disabled={loading}>{loading ? 'Wait ...' : 'Next'}</button>
            </form>
            <div className={styles["have-account"]}>
                <p>Have an account? <span onClick={() => props.changeStepName('sign-in')}>Sign in</span></p>
            </div>
        </>}
        {step === 2 && <>
            <form onSubmit={verifyOtp}>
                <input type="text" value={newMobileNumber? newMobileNumber :mobileNumber} disabled/>
                <p className={styles['resend-otp']}>
                    {
                        timer > 0
                        ?<span style={{color:'#747474',cursor:'default'}}> Resend in {timer > 0 && `${timer}s`} </span>
                        :<>Didn't get OTP? <span onClick={otpSend}>Resend</span></>
                    }
                </p>
                {/* <label>Enter OTP</label> */}
                <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" required/>
                <button type="submit" className="btn primary-button" disabled={loading}>{loading ? 'Wait ...' : 'Verify OTP'}</button>
            </form>
        </>}
</>)
}