import { useState } from "react";
import styles from "./fastag.module.css"
import { Link } from "react-router-dom";
import tokenapi, {fastagDetails} from "../Base/TokenConfig";
import Message from '../Components/Message/Message';
import { decrypt } from "../Utils/DecryptFunction";
import { useNavigate  } from "react-router-dom";


export default function ChallanDetailFlow() {
    const [stepName, setStepName] = useState('enter-details');
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage ] = useState('');
    const [decryptData, setDecryptData ] = useState('');
    const [loading, setLoading ] = useState(false);

    const navigate = useNavigate();


    const completeFastagDetail = () => {
        setLoading(true);
        tokenapi.post(fastagDetails, {
            vehicleNumber: vehicleNumber
        })
        .then(res => {
            if(res.data.data) {
                const result = decrypt(res.data.data, res.data.txn, "fastag-Details");
                setDecryptData(result);
            }
            if(res.data.code === 200) {
                setLoading(false);
                setStepName("fastag-details")
                setMessageClass("success");
                setMessage("Fastag detailed fetched");
                setTimeout(() => {
                    setMessage('');
                }, 3000);
            } else if(res.data.code === 401) {
                navigate('/signout');
            } else {
                setLoading(false);
                setMessageClass("error");
                setMessage("try again");
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
            setMessageClass('error');
            setMessage("Please try again")
        })
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {stepName === "enter-details" && 
        <>
            <div className={styles["vehicle-flow-header"]}>
                <div className={styles["header"]}>
                    <p><img src="./images/back-arrow-logo.svg" alt=""/> <Link to="/"><span>Back</span></Link></p>
                    <div className={styles["header-image"]}>
                        <img src= "./images/challan-details-image.svg" alt=""/>
                        <p>Get your Fastag details</p>
                    </div>
                </div>
            </div>
            <div className={styles["input-detail"]}>
                <h3 className={styles["enter-challan-details"]}>Enter Vehicle Details</h3> 
                <div className={styles["search-details"]}>
                    <input type="text" value={vehicleNumber} placeholder="ex. HRXXN9090" onChange={(e) =>setVehicleNumber(e.target.value)}/>
                    {/* <button className={vehicleNumber? "btn primary-button":''} onClick={() => completeFastagDetail()}>{loading?"wait...":"Search"}</button> */}
                    {vehicleNumber 
                        ?
                        <> {loading
                            ?<button className="btn primary-button">wait...</button>
                            :<button className="btn primary-button" onClick={() => completeFastagDetail()}>Search</button>
                        }</>
                        :<button >Search</button>

                    }
                </div>
            </div>
        </>}  
        {stepName === "fastag-details" &&
            <>
            <div className={styles["history-header"]}>
                <p className={styles["back-option"]} onClick={() => setStepName("enter-details")}><img src="./images/back-arrow-logo.svg" alt="" /> Back</p>
                <div className={styles["vehicle-complete-info"]}>
                    <div><img src="./images/number-info-image.svg" alt=""/></div>
                    <div>
                        <p>Vehicle Fastag Details</p>
                        <span></span>
                    </div>   
                </div>
            </div>
                <h3 className={styles["history"]}>Fastag-Details</h3>
                <div style={{padding: "15px", border:"1px solid #FFFFFF29"}}>
                <div className={styles["challan-no"]}>Fastag Id : {decryptData.data.TagId}</div>
                    <div className={styles["challan-final-data"]}>
                        {/* <p>TagId: <span>{decryptData.data.TagId}</span></p> */}
                        <p>VRN: <span>{decryptData.data.VRN}</span></p>
                        <p>Tag Status: <span>{decryptData.data["Tag Status"]}</span></p>
                        <p>Vehicle Class: <span>{decryptData.data["Vehicle Class"]}</span></p>
                        <p>Action: <span>{decryptData.data["Action"]}</span></p>
                        <p>Issue Date: <span>{decryptData.data["Issue Date"]}</span></p>
                        <p>Issuer Bank: <span>{decryptData.data["Issuer Bank"]}</span></p>
                    </div>
                </div>
                <div style={{padding: "15px", border:"1px solid #FFFFFF29;"}}></div>
        </>}
</>)
}