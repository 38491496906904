export const setSession = (x, y) => {
    localStorage.setItem("token", x);
    localStorage.setItem("profile-details", y);
}

export const getUserToken = () => {
    return localStorage.getItem("token") || null;
}

export const removeSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("profile-details");
}

export const getProfileDetails = () => {
    return localStorage.getItem('profile-details') || null;
}

export const setAuthToken = (x) => {
    localStorage.setItem("authToken", x);
}

export const getAuthToken = () => {
    return localStorage.getItem("authToken");
}

export const removeAuthToken = () => {
    localStorage.removeItem("authToken");
}