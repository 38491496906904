import axios from "axios";
import { getUserToken, getAuthToken } from "../Utils/Common";
export const baseurl = process.env.REACT_APP_BASE_URL;

const tokenapi = axios.create({
    baseURL: baseurl
});

tokenapi.interceptors.request.use(
    (config) => {
        const token = getUserToken();
        const authToken = getAuthToken();
        if(token) config.headers.token = token;
        if(authToken) config.headers.authToken = authToken;
        return config;
    },
    (error) => {
        console.log("Error while fetching token.")
        return Promise.reject(error);
    }
);

export default tokenapi;

// Vehicle Basic details
export const vehicleBasicDetails = '/vehicleRc/basicDetails';

// Vehicle Complete Details
export const vehicleCompleteDetails = '/vehicleRc/fullDetails';

// OTP Verify after basic Detail Need to Verify Number
export const verifyOtp = "/vehicleRc/verifyOtp";

// Complete Challan Details
export const challanFullDetails = '/vehicleRc/eChallanDetails';

// fastag Details
export const fastagDetails= '/vehicleRc/fasTagDetails';

// Get Profile Details API
export const profileDetails = "/userLogin/userProfileV1";

// Get All Vehicle Hits Data
export const vehicleHistory = "/vehicleRc/rcBasic";

// Get Fastag History Details
export const fastagHistory = "/vehicleRc/rcFasTag";

// Get Challan History
export const challanHistory = "/vehicleRc/rcChallan";

// Delete History
export const deleteHistory = "/vehicleRc/deleteRc";