import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getUserToken } from './Utils/Common';
import Login from './Login/Login';
import History from './History/History';
import VehicleDetail from './VehicleDetail/VehicleDetail';
import ChallanDetail from './ChallanDetail/ChallanDetail';
import Fastag from './Fastag/Fastag';
import Profile from './Profile/Profile';
import HelpUs from './HelpUs/HelpUs';
import Home from './Home/Home';
import RtoUpdate from './Blogs/RtoUpdate';
import CheckInsurance from './Blogs/CheckInsurance';
import Signout from './Signout/Signout';

export default function App() {
    // Function to check if the user is authenticated
    const isUserAuthenticated = () => {
        if(getUserToken()){
            return true;
        } else {
            return false;
        }
    };
    
    const PrivateRouteWrapper = ({ element }) => {
        // If the user is not authenticated, redirect to the login page
        if (!isUserAuthenticated()) {
            return <Navigate to="/login" />;
        }
      
        // Render the private route if the user is authenticated
        return element;
    };

    const PublicRouteWrapper = ({ element }) => {
        // If the user is authenticated, redirect to the dashboard page
        if (isUserAuthenticated()) {
            return <Navigate to="/" />;
        }
      
        // Render the public route if the user is not authenticated
        return element;
    };

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route exact path="/" element={<PrivateRouteWrapper element={<Home />} />} />
                    <Route path="/rto-updates" element={<PrivateRouteWrapper element={<RtoUpdate />} />} />
                    <Route path="/insurance-validation" element={<PrivateRouteWrapper element={<CheckInsurance />} />} />
                    <Route path="/login" element={<PublicRouteWrapper element={<Login />} />} />
                    <Route path="/history" element={<PrivateRouteWrapper element={<History />} />} />
                    <Route path="/profile" element={<PrivateRouteWrapper element={<Profile />} />} />
                    <Route path="/vehicle-details" element={<PrivateRouteWrapper element={<VehicleDetail />} />} />
                    <Route path="/challan-details" element={<PrivateRouteWrapper element={<ChallanDetail />} />} />
                    <Route path="/fastag-details" element={<PrivateRouteWrapper element={<Fastag />} />} />
                    <Route path="/help-us" element={<PrivateRouteWrapper element={<HelpUs />} />} />
                    <Route path="/signout" element={<PrivateRouteWrapper element={<Signout />} />} />
                </Routes>
            </div>
        </Router>
    );
}