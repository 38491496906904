import styles from "./helpus.module.css" ;
import Footer from "../Components/Footer/Footer";

export default function HelpUs() {

    return(<>
    
            <div className={styles["help-box"]}>
                <img src="./images/rto-image.svg" alt=""/>
                <div className={styles["help-and-support"]}>
                    <img src="./images/help.svg" alt="" />
                    <p>Help & support</p>
                </div>
            </div>
            <div className={styles["contact-us"]}>
                <div className={styles["contact-info"]}>
                    <img src="./images/callus.svg" alt=""/>
                    <div>
                        <p>Call Us</p>
                        <span>7832-3928908</span>
                    </div>
                </div>
                <hr style={{border:"1px solid rgba(46, 53, 86, 1)"}} />
                <div className={styles["email-info"]}> 
                    <img src="./images/emailus.svg" alt="" />
                    <div>
                        <p>Email us</p>
                        <span>helpdeskrtovahan@gmail.com</span>
                    </div>
                </div>
            </div>
     <Footer/>
</>)
}