import { useState } from "react";
import { Link } from "react-router-dom";
import tokenapi, { challanFullDetails } from '../Base/TokenConfig';
import Message from "../Components/Message/Message";
import { decrypt } from "../Utils/DecryptFunction";
import styles from "./challandetail.module.css";
import { useNavigate } from "react-router-dom";

export default function ChallanDetailFlow() {
    const [stepName, setStepName] = useState('enter-details');
    const [vehicleNumber, setVehicleNumber] = useState("");
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage ] = useState('');
    const [successData, setSuccessData ] = useState(' ');
    const [decryptData, setDecryptData ] = useState('');
    const [loading, setLoading ] = useState(false);

    const navigate = useNavigate();

    const searchChallanDetails = () => {
        setLoading(true);
        tokenapi.post(challanFullDetails, {
            vehicleNumber : vehicleNumber
        })
        .then(res => {
            if(res.data.data) {
                const result = decrypt(res.data.data, res.data.txn, "fastag-Details");
                setDecryptData(result);
            }
            setLoading(false);
            if(res.data.code === 200){
                setStepName('total-challan');
                setMessageClass('success');
                setMessage("success");
                setSuccessData('true');
                setTimeout(() => {
                    setMessage('');
                }, 3000);
            } else if(res.data.code === 401) {
                navigate('/signout');
            } else {
                setStepName('total-challan');
                setMessageClass('error');
                setMessage("Data not found");
                setSuccessData('false');
                setTimeout(() =>{
                    setMessage('');
                }, 3000)
            }
        })
        .catch(error => {
            setLoading(false);
            console.log(error);
            setMessageClass('error');
            setMessage("Something went wrong! Please try again")
        })
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {stepName === "enter-details" &&
        <>
            <div className={styles["vehicle-flow-header"]}>
                <div className={styles["header"]}>
                    <p><img src="./images/back-arrow-logo.svg" alt=""/> <Link to="/"><span>Back</span></Link></p>
                    <div className={styles["header-image"]}>
                        <img src= "./images/challan-details-image.svg" alt=""/>
                        <p>Get challan details of any vehicle</p>
                    </div>
                </div>
            </div>
            <div className={styles["input-detail"]}>
                <h3 className={styles["enter-challan-details"]}>Enter Vehicle Details</h3>
                <div className={styles["search-details"]}>
                    <div className={styles["input-field"]}>
                        <input type="text" value={vehicleNumber} placeholder="ex. HRXXN9090" onChange={(e) =>setVehicleNumber(e.target.value)}/>
                    </div>
                    {vehicleNumber
                        ?
                            <>{loading
                                ?<button className="btn primary-button">wait...</button>
                                :<button className="btn primary-button" onClick={() => searchChallanDetails()}>Search</button>
                            }
                            </>
                        :<button >Search</button>
                    }
                </div>
            </div>
        </>}
        {stepName === "total-challan" &&
            <>
            <div className={styles["history-header"]}>
                <p className={styles["back-option"]} onClick={() => setStepName("enter-details")}><img src="./images/back-arrow-logo.svg" alt="" /> Back</p>
                <div className={styles["vehicle-complete-info"]}>
                    <div><img src="./images/number-info-image.svg" alt=""/></div>
                    <div>
                        <p>Vehicle Number</p>
                        <span>{vehicleNumber.toUpperCase()}</span>
                    </div>
                </div>
            </div>

            

            {successData==='true' ?<>

            <h3 className={styles["history"]}>Vehicle-Details</h3>
            {decryptData.data.map((item,i) =>  <>
                <div style={{padding: "15px", border:"1px solid #FFFFFF29;"}} key={i}>
                <div className={styles["challan-no"]}>Challan #HR464162387878466764 </div>
                    <div className={styles["challan-final-data"]}>
                        <p>Challan Number<span>{item.challanNumber ? item.challanNumber : "-"}</span></p>
                        <p>Amount<span>{item.amount ? item.amount  : "-"}</span></p>
                        <p>Challan date<span>{item.challanDate ? item.challanDate : "-"}</span></p>
                        <p>challan status<span>{item.challanStatus ? item.challanStatus : "-"}</span></p>
                        <p>violator name<span>{item.accusedName ? item.accusedName : "-"}</span></p>
                        <p>Offense Details<span>{item.offenseDetails ? item.offenseDetails : "-"}</span></p>
                        <p>State<span>{item.state ? item.state : "-"}</span></p>
                        <p>Court Status<span>{item.court_status ? item.court_status : "-"}</span></p>
                        <p>Accused Name<span>{item.accusedName ? item.accusedName : "-"}</span></p>
                    </div>
                </div>
                <div style={{padding: "15px", border:"1px solid #FFFFFF29;"}}></div>
            </> )

            }
            </>
            :
            <>
                <div style={{padding: "15px", border:"1px solid #FFFFFF29;"}}>
                    <div className={styles["no-challan-found"]}>
                        <img src="./images/no-challan-found.svg" alt="" />
                        <h3>No Challan Found</h3>
                        <div>
                            Challan details are unavailable or not found for the RC number {vehicleNumber.toUpperCase()} registered with the RTO.
                        </div>
                    </div>
                </div>
            </>}
        </>}
</>)
}