import React, { useEffect, useState } from 'react';
import api, { generateAuthToken } from '../Base/Config';
import { setAuthToken } from '../Utils/Common';
import SignUp from './Components/SignUp';
import SignIn from './Components/SignIn';
import styles from './login.module.css';

export default function Login() {
    const [stepName, setStepName] = useState('sign-in');

    const changeStepName = (x) => setStepName(x);

    useEffect(() => {
        getAuthTokenFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAuthTokenFunction = () => {
        api.post(generateAuthToken, {
            clientId: process.env.REACT_APP_CLIENTID,
            secretKey: process.env.REACT_APP_SECRETKEY
        })
        .then(res => {
            if(res.data.code === 200){
                setAuthToken(res.data.oAuthToken);
            } else {
                console.log(res);
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    return(<>
        <div className={styles['login-page-container']}>
            <div className={styles['logo-container']}>
                <img src="./images/home.svg" alt="" />
            </div>
            {stepName === 'sign-in' && <SignIn changeStepName={changeStepName} />}
            {stepName === 'sign-up' && <SignUp changeStepName={changeStepName} />}
        </div>
    </>)
}