import axios from "axios";
import { getAuthToken } from "../Utils/Common";
export const baseurl = process.env.REACT_APP_BASE_URL;

const api = axios.create({
    baseURL: baseurl
});

api.interceptors.request.use(
    (config) => {
        const authToken = getAuthToken();
        if(authToken) config.headers.authToken = authToken;
        return config;
    },
    (error) => {
        console.log("Error while fetching token.")
        return Promise.reject(error);
    }
);

export default api;

// Check Mobile Number Exist or Not
export const checkMobile = "/userCheck/mobile";

// // Send OTP USING VAAHAN
// export const sendOtp = "/userLogin/sendOtp";

// // Otp Verify
// export const otpVerification = "/userLogin/VerifyOtp";

// Generate Auth Token 
export const generateAuthToken = "/generate/oAuthToken";

// SEND OTP 
export const sendOtp = "/userLogin/sendOtpV1"

// Otp Verify
export const otpVerification = "/userLogin/VerifyOtpV1";