import React from 'react';
import styles from './message.module.css';

export default function Message(props) {
    return (
        <div className={styles["message-container"]}>
            <div className={styles[`${props.messageClass}-message-box`]}>
                <div>
                    <h4>{props.messageClass}!</h4>
                    <h5>{props.message}</h5>
                </div>
                <img src='./images/Union.svg' alt='' onClick={props.closeMessage} />
            </div>
        </div>
    );
}