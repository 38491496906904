import React, {useEffect, useState } from 'react';
import styles from '../history.module.css';
import tokenapi, {challanHistory, deleteHistory} from '../../Base/TokenConfig';
import { decrypt } from '../../Utils/DecryptFunction';
import Message from "../../Components/Message/Message";

export default function History(props) {
    const [challanDetails, setChallanDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage ] = useState('');
    const [stepName, setStepName ] = useState("challan-history");
    const [singleDetails, setSingleDetails ] = useState([]);

    const challanHistoryDetail = () => {
        tokenapi.get(challanHistory)
        .then((res) => {
            setLoading(false);
            const result = decrypt(res.data.data, res.data.txn, "challan-details");
            if(res.data.code === 200) {
                setChallanDetails(result);
            } else {
                setChallanDetails([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const historyDelete = (id) => {
        tokenapi.post(deleteHistory, {
            referenceKey: "challan",
            id: id
        })
        .then((res) => {
            decrypt(res.data.data, res.data.txn, "History-Delete-Data");
            if(res.data.code === 200) {
                challanHistoryDetail();
                setMessageClass("success");
                setMessage("Data deleted successfully");
            } else {
                setMessageClass("error");
                setMessage("please try again");
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }


    useEffect(() => {
        challanHistoryDetail();
    }, [])

    const fullData = (item) => {
        setStepName("full-details");
        setSingleDetails(item);
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {stepName === "challan-history" && <>
        <div className={styles["history-header"]}>
            <p className={styles["back-option"]} onClick={() => props.setStepName("vehicle-challan-history")}><img src="./images/back-arrow-logo.svg" alt=""/> Back</p>
            <div className={styles["vehicle-info"]}>
                <div><img src="./images/vehicle-search-image.svg" alt=""/></div>
                <div>Challan search history</div>   
            </div>
        </div>
        {
            loading
            ?"loading"
            :<>
                {
                    challanDetails.length > 0
                    ?<div style={{padding: "20px"}}>
                        <h3 className={styles["history"]}>History</h3>
                        {challanDetails.map((item, i) => {
                            if(!item.data) return null;
                            return (
                                // <div className={styles["vehicle-history"]} key={i} onClick={() => setStepName("full-details")}>
                                <div className={styles["vehicle-history"]} key={i} onClick={() => fullData(item)}>
                                    <img src="./images/bike-logo.svg" alt=""/>
                                    <div>
                                        <div>
                                            <p>{item.data[0].challanNumber}</p>
                                            <span>{item.data[0].challanStatus}</span>
                                        </div>
                                    </div>
                                    <div className={styles['delete-icons']} onClick={() => historyDelete(item._id)}>
                                        <i className="fa fa-trash"></i>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    :<div style={{padding: "15px", border:"1px solid #FFFFFF29;"}}>
                        <div className={styles["no-challan-found"]}>
                            <img src="./images/no-challan-found.svg" alt="" />
                            <h3>No Challan History Found</h3>
                            <div>
                                Challan details are unavailable or not found for the RC number registered with the RTO.
                            </div>
                        </div>
                    </div>
                }
            </>
        }
        </>}
        {stepName === "full-details" &&
            <>
            <div className={styles["history-header"]}>
                <p className={styles["back-option"]} onClick={() => props.setStepName("vehicle-challan-history")}><img src="./images/back-arrow-logo.svg" alt=""/> Back</p>
                <div className={styles["vehicle-complete-info"]}>
                    <div><img src="./images/number-info-image.svg" alt=""/></div>
                    <div>
                       <span>Vehicle Search history</span>
                       <p>{singleDetails?.input?.vehicleNumber}</p>
                    </div>   
                </div>
            </div>
            <h3 className={styles["history"]}>Details</h3>
            <div style={{padding: "15px"}}>
                {singleDetails.data.map((list,i) => (
                    <div className={styles["vehicle-final-data"]} key={i}>
                        <p>Violator name<span>{list.accusedName}</span></p>
                        <p>Amount<span>{list?.amount}</span></p>
                        <p>Challan date<span>{list.challanDate}</span></p>
                        <p>Challan status<span>{list.challanStatus}</span></p>
                    </div>
                ))}
            </div>
        </>
        }
    </>)
}