import axios from "axios";
export const baseurl = process.env.REACT_APP_PROD_URL;

const api = axios.create({
    baseURL: baseurl,
    headers: {
        clientId: process.env.REACT_APP_CLIENTID_PROD,
        secretKey: process.env.REACT_APP_SECRETKEY_PROD
    }
});

export default api;

// Check Mobile Number Exist or Not
export const fuelPriceByState = "/invincible/fuelPriceByState";