import { useState, useEffect } from 'react';
import styles from './footer.module.css';
import { Link } from 'react-router-dom';

export default function Footer() {
    const[helpUrl, setHelpUrl] = useState('');

    useEffect(() => {
        setHelpUrl(window.location.pathname);
    }, [])

return(<>
    <div style={{height: '70px'}}></div>
    <div className={styles['footer']}>
        <Link to="/"><img src={`./images/Footer/home${helpUrl === '/' ? "-color" : ''}.svg`} alt=''/><span style={{color:helpUrl === '/' ? "#48A0FA" : "#FFF"}}>Home</span></Link>
        <Link to='/history'><img src={`./images/Footer/history${helpUrl === '/history' ? "-color" : ''}.svg`}  alt=''/><span style={{color:helpUrl === '/history' ? "#48A0FA" : "#FFF"}}>History</span></Link>
        <Link to='/profile'><img src={`./images/Footer/profile${helpUrl === '/profile' ? "-color" : ''}.svg`} alt=''/><span style={{color:helpUrl === '/profile' ? "#48A0FA" : "#FFF"}}>Profile</span></Link>
        <Link to='/help-us'><img src={`./images/Footer/help${helpUrl === '/help-us' ? "-color" : ''}.svg`} alt=''/><span style={{color:helpUrl === '/help-us' ? "#48A0FA" : "#FFF"}}>Help</span></Link>
    </div>
</>);
}