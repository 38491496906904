import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getProfileDetails } from '../Utils/Common';
import Footer from '../Components/Footer/Footer';
import styles from './history.module.css';
import SearchDetails from '../History/Component/SearchDetails';
import FastagHistory from '../History/Component/FastagHistory';
import ChallanHistory from '../History/Component/ChallanHistory';


export default function History() {
    const profileData = JSON.parse(getProfileDetails());
    const[stepName, setStepName] = useState('vehicle-challan-history');
    
    return(<>
        {stepName === "vehicle-challan-history" && <>
            <div className={styles["header"]}>
                <div className={styles["home-head"]}>
                    <div>
                        <img src="./images/rto-image.svg" alt=""/>
                    </div>
                    <div>
                    <Link to="/profile"><img src="./images/profile-icon.svg" alt=""/></Link>
                    </div>
                </div>
                <div className={styles['profile-info']}>
                    <div>
                        <img src='./images/profile.png' alt='profile' />
                    </div>
                    <div>
                        <p>Welcome!</p>
                        <h3>{profileData?.mParCitizenUser?.mparCitizenUser?.ctzDispName}</h3>
                    </div>
                </div>
            </div>
            <div style={{padding: '20px'}}>
                <h3 className={styles["history"]}>History</h3>
                <div className={styles["vehicle-history"]} onClick={() => setStepName("vehicle-details")}>
                    <img src="./images/vehicle-search-image.svg" alt=""/>
                    <div>Vehicle search history</div>
                    <img src="./images/next-arrow-logo.svg" alt=""/>
                </div>
                <div className={styles["vehicle-history"]} onClick={() => setStepName("fastag-details")}>
                    <img src="./images/challan-service-image.svg" alt=""/>
                    <div>Fastag search history</div>   
                    <img src="./images/next-arrow-logo.svg" alt=""/>
                </div>
                <div className={styles["vehicle-history"]} onClick={() => setStepName("challan-details")}>
                    <img src="./images/challan-service-image.svg" alt=""/>
                    <div>Challan search history</div>   
                    <img src="./images/next-arrow-logo.svg" alt=""/>
                </div>
            </div>
        </>}
            {stepName === "vehicle-details" &&
            < SearchDetails  setStepName= {setStepName}/>
            }
            {stepName === "fastag-details" && 
                < FastagHistory setStepName= {setStepName}/>
            }
            {stepName === "challan-details" && 
                <ChallanHistory setStepName= {setStepName}/>
            }

            {/* {stepName === "complete-detail" &&
            <>
                <div className={styles["history-header"]}>
                    <p className={styles["back-option"]} onClick={() => setStepName("vehicle-details")}><img src="./images/back-arrow-logo.svg" alt="" /> Back</p>
                    <div className={styles["vehicle-complete-info"]}>
                        <div><img src="./images/number-info-image.svg" alt=""/></div>
                        <div>
                           <span>Vehicle Search history</span>
                           <p>H*8*N*9*3</p>
                        </div>   
                    </div>
                </div>
                <h3 className={styles["history"]}>Details</h3>
                <div style={{padding: "15px"}}>
                    <div className={styles["vehicle-final-data"]}>
                        <p>Vehicle Number<span>H*8*N*9*3</span></p>
                        <p>Owner Name<span>P*n*a* K*m*r D*i*a*</span></p>
                        <p>Registering Authority<span>SDM BH***L, H*r*y*n*</span></p>
                        <p>Vehicle<span>M-Cycle/Scooter(2WN)</span></p>
                        <p>Fuel type<span>P*T*O*</span></p>
                        <p>Emission Norm<span>BHARAT STAGE VI</span></p>
                        <p>Vehicle Age<span>0 Years & 4 months</span></p>
                        <p>Vehicle Status<span>ACTIVE</span></p>
                        <p>Registration Details<span>**-**-2***</span></p>
                        <p>Fitness Valid Upto<span>**-**-2***</span></p>
                        <p>Tax valid Upto<span>LTT</span></p>
                        <p>Insurance Valid Upto<span>**-**-2***</span></p>
                        <p>PUCC Valid Upto<span>**-**-2***</span></p>
                    </div>
                </div>

            </>} */}
            {/* {stepName === "challan-detail" &&
            <>
                <div className={styles["history-header"]}>
                    <p className={styles["back-option"]} onClick={() => setStepName("vehicle-challan-history")}><img src="./images/back-arrow-logo.svg" alt="" /> Back</p>
                    <div className={styles["vehicle-info"]}>
                        <div><img src="./images/vehicle-search-logo.svg" alt=""/></div>
                        <div>Challan Search history</div>   
                    </div>
                </div>
                <h3 className={styles["history"]}>History</h3>
                <div className={styles["vehicle-history"]}>
                    <img src="./images/bike-logo.svg" alt=""/>
                    <div alt="" onClick={() => setStepName("complete-challan-info")}>
                        <p>H*8*N*9*3</p>
                        <span>P*N*A* K*M*R D*I*A*</span>
                    </div>   
                    <img src="./images/three-dots.svg" alt="" />
                </div>
                <div className={styles["vehicle-history"]}>
                    <img src="./images/car-logo.svg" alt=""/>
                    <div>
                        <p>H*8*N*9*3</p>
                        <span>P*N*A* K*M*R D*I*A*</span>
                    </div>  
                    <img src="./images/three-dots.svg" alt=""/>
                </div>
            </>}
            {stepName === "complete-challan-info" &&
            <>
                <div className={styles["history-header"]}>
                    <p className={styles["back-option"]} onClick={() => setStepName("vehicle-details")}><img src="./images/back-arrow-logo.svg" alt="" /> Back</p>
                    <div className={styles["vehicle-complete-info"]}>
                        <div><img src="./images/number-info-image.svg" alt=""/></div>
                        <div>
                           <span>Vehicle Search history</span>
                           <p>H*8*N*9*3</p>
                        </div>   
                    </div>
                </div>
                <h3 className={styles["history"]} onClick={() => setStepName("no-challan-found")}>Details</h3>
                <div style={{padding: "15px"}}>
                    <div className={styles["vehicle-final-data"]}>
                        <div className={[""]}>
                            Challan #HR464162387878466764
                        </div>
                        <p>amount<span>₹1000</span></p>
                        <p>Challan date<span>4-**-2*** 2:58PM</span></p>
                        <p>challan status<span>PENDING</span></p>
                        <p>violator name<span>P*n*a* D*i*a*</span></p>
                        <p>Rc number<span>H*8*N*9*3</span></p>
                    </div>
                </div>

            </>} */}
        <Footer />
    </>)
}