import React, {useEffect, useState } from 'react';
import styles from '../history.module.css';
import tokenapi, {fastagHistory, deleteHistory} from '../../Base/TokenConfig';
import { decrypt } from '../../Utils/DecryptFunction';
import Message from "../../Components/Message/Message";


export default function History(props) {
    const [fastagDetails, setFastagDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage ] = useState('');
    const [stepName, setStepName ] = useState("fastag-details");
    const [singleDetails, setSingleDetails ] = useState([]);

    const fastagHistoryDetail = () =>{
        tokenapi.get(fastagHistory)
        .then((res) => {
            setLoading(false);
            const result = decrypt(res.data.data, res.data.txn, "fastag-History-Details");
            if(res.data.code === 200) {
                setFastagDetails(result);
            } else {
                setFastagDetails([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const historyDelete = (id) => {
        tokenapi.post(deleteHistory, {
            referenceKey: "fasTag",
            id: id
        })
        .then((res) => {
            decrypt(res.data.data, res.data.txn, "History-Delete-Data");
            if(res.data.code === 200) {
                fastagHistoryDetail();
                setMessageClass("success");
                setMessage("Data deleted successfully");
            } else {
                setMessageClass("error");
                setMessage("Please try again");
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        fastagHistoryDetail();
    }, [])

    const fullData = (item) => {
        setStepName("full-details");
        setSingleDetails(item);
    }
    
    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        {stepName === "fastag-details" && <>
            <div className={styles["history-header"]}>
                <p className={styles["back-option"]} onClick={() => props.setStepName("vehicle-challan-history")}><img src="./images/back-arrow-logo.svg" alt=""/> Back</p>
                <div className={styles["vehicle-info"]}>
                    <div><img src="./images/vehicle-search-image.svg" alt=""/></div>
                    <div>Fastag search history</div>   
                </div>
            </div>
            {
                loading
                ?"loading"
                :<>
                {
                    fastagDetails.length > 0
                    ?<div style={{padding: "20px"}}>
                        <h3 className={styles["history"]}>History</h3>
                        {fastagDetails.map((item, i) => {
                            if(!item.data) return null;
                            return (
                                <div className={styles["vehicle-history"]} key={i} onClick={() => fullData(item)}>
                                    <img src="./images/bike-logo.svg" alt=""/>
                                    <div>
                                        <div>
                                            <p>{item.data.VRN}</p>
                                            <span>{item.data.TagId}</span>
                                        </div>
                                    </div>
                                    <div className={styles['delete-icons']} onClick={() => historyDelete(item._id)}>
                                        <i className="fa fa-trash"></i>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    :<div style={{padding: "15px", border:"1px solid #FFFFFF29;"}}>
                        <div className={styles["no-challan-found"]}>
                            <img src="./images/no-challan-found.svg" alt="" />
                            <h3>No Fastag Data Found</h3>
                            <div>
                                Fastag details are unavailable or not found for the RC number registered with the RTO.
                            </div>
                        </div>
                    </div>
                }
            </>}  
            </>}
            {stepName === "full-details" &&
            <>
                <div className={styles["history-header"]}>
                    <p className={styles["back-option"]} onClick={() => props.setStepName("vehicle-challan-history")}><img src="./images/back-arrow-logo.svg" alt=""/>Back</p>
                    <div className={styles["vehicle-complete-info"]}>
                        <div><img src="./images/number-info-image.svg" alt=""/></div>
                        <div>
                        <span>Vehicle Search history</span>
                        <p>{singleDetails?.input?.vehicleNumber}</p>
                        </div>   
                    </div>
                </div>
                <h3 className={styles["history"]}>Details</h3>
                <div style={{padding: "15px"}}>
                        <div className={styles["vehicle-final-data"]} >
                            <p>TagId<span>{singleDetails?.data?.TagId}</span></p>
                            <p>Issue Date<span>{singleDetails?.data?.['Issue Date']}</span></p>
                            <p>Issuer Bank<span>{singleDetails?.data?.['Issuer Bank']}</span></p>
                            <p>Tag Status<span>{singleDetails?.data?.['Tag Status']}</span></p>
                            <p>Vehicle Class<span>{singleDetails?.data?.VRN}</span></p>
                            <p className={styles['action-data']}>Action<span>{singleDetails?.data?.Action}</span></p>
                        </div>
                </div>
            </>}
        </>)}