import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getProfileDetails } from '../Utils/Common';
import Footer from '../Components/Footer/Footer';
import styles from './profile.module.css';

export default function Profile() {
    const profileData = JSON.parse(getProfileDetails());
    const [stepName, setStepName] = useState('profile');

    return(<>
        {stepName === "profile" && <>
            <div className={styles["profile-header"]}>
                <div className={styles["rto-and-profile"]}>
                <Link to="/">
                    <img src="./images/rto-image.svg" alt=""/>
                </Link>
                </div>
                <div className={styles["my-account-image"]}>
                    <div>
                        <img src="./images/profile-plus.svg" alt=""/>
                    </div>
                </div>
                <div className={styles["profile-name"]}>
                    {profileData?.mParCitizenUser?.mparCitizenUser?.ctzDispName}
                </div>
            </div>
            <div className={styles["profile-info"]}>
                {/* <div className={styles['profile-card']} onClick={() => setStepName('profile-edit-page')}>
                    <img src="./images/edit-profile.svg" alt=""/>
                    <span>Edit profile</span>
                    <img src="./images/forward-arrow.svg" alt="" />
                </div> */}
                <Link to="/signout">
                    <div className={styles['profile-card']}>
                        <img src="./images/sign-out.svg" alt=""/>
                        <span>Sign out</span>
                        <img src="./images/forward-arrow.svg" alt="" />
                    </div>
                </Link>
                {/* <div className={styles['profile-card']}>
                    <img src="./images/delete-account.svg" alt=""/>
                    <span>Delete account</span>
                    <img src="./images/forward-arrow.svg" alt=""/>
                </div> */}
            </div>
        </>}
        {stepName === "profile-edit-page" && <>
            <div className={styles["profile-editing"]}>
                <div onClick={() => setStepName("profile")}>
                    <img src="./images/back-arrow-logo.svg" alt=""/>
                </div>
                <div>Back</div>
                <div style={{fontWeight: "500", fontSize:"18px"}} >Edit profile</div>
            </div>
            <div className={styles["profile-data"]}>
                <div>
                    <label>Name</label>
                    <input type="text" placeholder="Enter Name"></input>
                </div>
                <div>
                    <label>Mobile No.</label>
                    <input type="text" placeholder="Enter Mobile No"></input>
                </div>
                <div>
                    <label>Email ID</label>
                    <input type="text" placeholder="Enter Email ID"></input>
                </div>
            </div>
        </>}
        <Footer />
    </>)
}