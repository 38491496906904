import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { getProfileDetails} from '../Utils/Common';
import api, {fuelPriceByState} from "../Base/ProductionConfig";
import Footer  from "../Components/Footer/Footer";
import styles from './home.module.css';
import { useEffect, useState } from 'react';
import Message from '../Components/Message/Message';

export default function Home() {
    const profileData = JSON.parse(getProfileDetails());
    const[message, setMessage] = useState('');
    const[messageClass, setMessageClass] = useState('');
    const[fuelRates, setfuelRates] = useState('');
    const[defaultState, setDefaultState] = useState('');

    const responsive = {
        // the naming can be any, depends on you.
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const validStateKey = ["assam", "bihar", "chandigarh", "chhattisgarh", "delhi", "goa", "gujarat", "haryana", "jharkhand", "karnataka", "kerala", "lakshadweep", "maharashtra", "manipur", "meghalaya", "mizoram", "nagaland", "odisha", "puducherry", "punjab", "rajasthan", "sikkim", "telangana","uttarakhand"]


    useEffect(() => {
        if(sessionStorage.getItem("fuel-price")) {
            setfuelRates(JSON.parse(sessionStorage.getItem("fuel-price")));
            setDefaultState(JSON.parse(sessionStorage.getItem("fuel-price")).stateId);
        } else {
            fuelPrices('delhi');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fuelPrices = (state) => {
            var newState = "";
            const filteredArray = validStateKey.filter(item => item === state);
            if(filteredArray.length > 0) {
                newState = filteredArray[0];
            } else {
                newState = "delhi";
            }
            setDefaultState(state);
            api.post(fuelPriceByState, {
                stateName: newState
            })
            .then((res) => {
                console.log(res);
                if(res.data.code === 200) {
                    setfuelRates(res.data.result);
                    sessionStorage.setItem("fuel-price", JSON.stringify(res.data.result));
                    setMessageClass("success");
                    setMessage(res.data.Message);
                } else {
                    setfuelRates("");
                    setMessageClass("success");
                    setMessage(res.data.Message);
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className={styles["header"]}>
            <div className={styles["home-head"]}>
                <div>
                    <img src="./images/rto-image.svg" alt=""/>
                </div>
                <div>
                <Link to="/profile"><img src="./images/profile-icon.svg" alt=""/></Link>
                </div>
            </div>
            <div className={styles['profile-info']}>
                <div>
                    <img src='./images/profile.png' alt='profile' />
                </div>
                <div>
                    <p>Welcome!</p>
                    <h3>{profileData?.mParCitizenUser?.mparCitizenUser?.ctzDispName}</h3>
                </div>
            </div>
        </div>
        <div className={styles['home-content']}>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                arrows={false}
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                containerClass="carousel-container"
            >
                <Link to="/rto-updates">
                    <div className={styles['banner-image']}>
                        <img src="./images/home/car-banner.svg" alt="banner"/>
                    </div>
                </Link>
                <Link to="/insurance-validation">
                    <div className={styles['banner-image']}>
                        <img src="./images/home/bike-banner.svg" alt="banner"/>
                    </div>
                </Link>
            </Carousel>
            <h3 className={styles['home-heading']}>Services</h3>
            <div className={styles['service-cards']}>
                <Link to="/challan-details">
                    <div className={styles['service-card']}>
                        <img src="./images/home/challan-details.svg" alt=""/>
                        <p className={styles['service-name']}>
                            Challan details
                            <img src="./images/forward-icon.svg" alt=""/>
                        </p>
                    </div>
                </Link>
                <Link to="/vehicle-details">
                    <div className={styles['service-card']}>
                        <img src="./images/home/vehicle-rc-details.svg" alt=""/>
                        <p className={styles['service-name']}>
                            Vehicle RC details
                            <img src="./images/forward-icon.svg" alt=""/>
                        </p>
                    </div>
                </Link>
                <Link to="/fastag-details">
                <div className={styles['service-card']}>
                    <img src="./images/home/fastag-details.svg" alt=""/>
                    <p className={styles['service-name']}>
                        FASTags details
                        <img src="./images/forward-icon.svg" alt=""/>
                    </p>
                </div>
                </Link>
                <div className={styles['service-card']}>
                    <div className={styles['coming-soon']}>
                        <img src="./images/home/buy-new-car.svg" alt=""/>
                        <p>Coming soon</p>
                    </div>
                    <p className={styles['service-name']}>
                        Buy new car
                        <img src="./images/forward-icon.svg" alt=""/>
                    </p>
                </div>
                <Link to="/history">
                    <div className={styles['service-card']}>
                        <div className={styles['coming-soon']}>
                            <img src="./images/home/service-history.svg" alt=""/>
                            {/* <p>Coming soon</p> */}
                        </div>
                        <p className={styles['service-name']}>
                            Service history
                            <img src="./images/forward-icon.svg" alt=""/>
                        </p>
                    </div>
                </Link>
                <div className={styles['service-card']}>
                    <div className={styles['coming-soon']}>
                        <img src="./images/home/sell-your-car.svg" alt=""/>
                        <p>Coming soon</p>
                    </div>
                    <p className={styles['service-name']}>
                        Sell your car
                        <img src="./images/forward-icon.svg" alt=""/>
                    </p>
                </div>
            </div>
            <h3 className={styles['home-heading']}>Nearby places</h3>
            <div className={styles['nearby-places-container']}>
                <div className={styles['nearby-places-box']}>
                    <img src="./images/home/petrol-pump.svg" alt=""/>
                    <p>Petrol pump</p>
                </div>
                <div className={styles['nearby-places-box']}>
                    <img src="./images/home/charging-station.svg" alt=""/>
                    <p>Charging station</p>
                </div>
                <div className={styles['nearby-places-box']}>
                    <img src="./images/home/wash-services.svg" alt=""/>
                    <p>Car wash services</p>
                </div>
                <div className={styles['nearby-places-box']}>
                    <img src="./images/home/rto-office.svg" alt=""/>
                    <p>RTO office</p>
                </div>
            </div>
            <h3 className={styles['home-heading']}>Check fuel prices</h3>
            <div className={styles['check-fuel-prices']}>
                <h3><label>State</label></h3>
                <form>
                    <select value={defaultState} onChange={(e) => fuelPrices(e.target.value)} >
                        <option value="">-- Select --</option>
                        <option value="andaman">Andaman and Nicobar Islands</option>
                        <option value="andhra" >Andhra Pradesh</option>
                        <option value="arunachal">Arunachal Pradesh</option>
                        <option value="assam">Assam</option>
                        <option value="bihar">Bihar</option>
                        <option value="chandigarh">Chandigarh</option>
                        <option value="chhattisgarh">Chhattisgarh</option>
                        <option value="dadranagar">Dadra and Nagar Haveli</option>
                        <option value="damadiu">Daman and Diu</option>
                        <option value="delhi">Delhi</option>
                        <option value="goa">Goa</option>
                        <option value="gujarat">Gujarat</option>
                        <option value="haryana">Haryana</option>
                        <option value="himachal">Himachal Pradesh</option>
                        <option value="jammu">Jammu and Kashmir</option>
                        <option value="jharkhand">Jharkhand</option>
                        <option value="karnataka">Karnataka</option>
                        <option value="kerala">Kerala</option>
                        <option value="lakshadweep">Lakshadweep</option>
                        <option value="madhya">Madhya Pradesh</option>
                        <option value="maharashtra">Maharashtra</option>
                        <option value="manipur">Manipur</option>
                        <option value="meghalaya">Meghalaya</option>
                        <option value="mizoram">Mizoram</option>
                        <option value="nagaland">Nagaland</option>
                        <option value="odisha">Odisha</option>
                        <option value="puducherry">Puducherry</option>
                        <option value="punjab">Punjab</option>
                        <option value="rajasthan">Rajasthan</option>
                        <option value="sikkim">Sikkim</option>
                        <option value="tamil">Tamil Nadu</option>
                        <option value="telangana">Telangana</option>
                        <option value="tripura">Tripura</option>
                        <option value="uttarakhand">Uttarakhand</option>
                        <option value="up">Uttar Pradesh</option>
                        <option value="bengal">West Bengal</option>
                    </select>
                </form>
                {/* <p className={styles["state-name"]}>Haryana <img src="./images/forward-arrow.svg" alt=""/></p> */}
                <div className={styles['fuel-prices-container']}>
                    <div className={styles['fuel-price']}>
                        <img src="./images/home/petrol-price.svg" alt="petrol"/>
                        <p>Petrol</p>
                        <h5>{fuelRates?.fuel?.petrol?.retailPrice}</h5>
                    </div>
                    <div className={styles['fuel-price']}>
                        <img src="./images/home/diesel-price.svg" alt="diesel"/>
                        <p>Diesel</p>
                        <h5>{fuelRates?.fuel?.diesel?.retailPrice}</h5>
                    </div>
                    <div className={styles['fuel-price']}>
                        <img src="./images/home/cng-price.svg" alt="cng"/>
                        <p>CNG</p>
                        <h5>{fuelRates?.fuel?.cng?.retailPrice ? fuelRates?.fuel?.cng?.retailPrice : "-"}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles['copyright']}>© {new Date().getFullYear()} RTO Vahan | All rights reserved</div>
        <Footer />
    </>)
}