import React, {useEffect, useState } from 'react';
import styles from '../history.module.css';
import tokenapi, {vehicleHistory, deleteHistory} from '../../Base/TokenConfig';
import { decrypt } from '../../Utils/DecryptFunction';
import Message from "../../Components/Message/Message";


export default function History(props) {
    const [vehicleDetails, setVehicleDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [messageClass, setMessageClass] = useState('');
    const [message, setMessage ] = useState('');
    const [stepName, setStepName ] = useState("search-history");
    const [singleDetails, setSingleDetails ] = useState([]);

    
    const vehicleHistoryDetail = (props) => {
        tokenapi.get(vehicleHistory)
        .then((res) => {
            setLoading(false);
            const result = decrypt(res.data.data, res.data.txn, "vehicle-details");
            if(res.data.code === 200) {
                setVehicleDetails(result);
            } else {
                setVehicleDetails([]);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    const historyDelete = (id) => {
        tokenapi.post(deleteHistory, {
            referenceKey: "basic",
            id: id
        })
        .then((res) => {
            decrypt(res.data.data, res.data.txn, "History-Delete-Data");
            if(res.data.code === 200) {
                vehicleHistoryDetail();
                setMessageClass("error");
                setMessage("Please try again");
            } else {
                setMessageClass("success");
                setMessage("Data deleted successfully");
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        vehicleHistoryDetail();
    }, [])
    
    const fullData = (item) => {
        setStepName("full-details");
        setSingleDetails(item);
    }

    return(<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
            {stepName === "search-history" && <>
                    <div className={styles["history-header"]}>
                        <p className={styles["back-option"]}  onClick={() => props.setStepName("vehicle-challan-history")}><img src="./images/back-arrow-logo.svg" alt=""/> Back</p>
                        <div className={styles["vehicle-info"]}>
                            <div><img src="./images/vehicle-search-image.svg" alt=""/></div>
                            <div>Vehicle search history</div>   
                        </div>
                    </div>
                    {
                        loading 
                        ? "loading"
                        :<>
                        { vehicleDetails.length > 0
                            ?<div style={{padding: "20px"}}>
                            <h3 className={styles["history"]}>History</h3>
                                {vehicleDetails.map((item, i) => {
                                    if(!item.data) return null;
                                    return (
                                        <div className={styles["vehicle-history"]} key={i} onClick={() => fullData(item)}>
                                            <img src="./images/bike-logo.svg" alt=""/>
                                            <div>
                                                <div>
                                                    <p>{item.data.regNo}</p>
                                                    <span>{item.data.owner}</span>
                                                </div>
                                            </div>
                                            <div className={styles['delete-icons']} onClick={() => historyDelete(item._id)}>
                                                <i className="fa fa-trash"></i>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            :<>
                                <div style={{padding: "15px", border:"1px solid #FFFFFF29;"}}>
                                    <div className={styles["no-challan-found"]}>
                                        <img src="./images/no-challan-found.svg" alt="" />
                                        <h3>No Vehicle Data Found</h3>
                                        <div>
                                            Vehicle details are unavailable or not found for the RC number registered with the RTO.
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                        </>
                    }
            </>}
            {stepName === "full-details" &&
            <>
            <div className={styles["history-header"]}>
                <p className={styles["back-option"]} onClick={() => props.setStepName("vehicle-challan-history")}><img src="./images/back-arrow-logo.svg" alt=""/>Back</p>
                <div className={styles["vehicle-complete-info"]}>
                    <div><img src="./images/number-info-image.svg" alt=""/></div>
                    <div>
                    <span>Vehicle Search history</span>
                    <p>{singleDetails?.input?.vehicleNumber}</p>
                    </div>   
                </div>
            </div>
            <h3 className={styles["history"]}>Details</h3>
            <div style={{padding: "15px"}}>
                <div className={styles["vehicle-final-data"]} >
                    <p>Owner<span>{singleDetails?.data?.owner}</span></p>
                    <p>Reg No<span>{singleDetails?.data?.regNo}</span></p>
                    <p>Reg Authority<span>{singleDetails?.data?.regAuthority}</span></p>
                    <p>Model<span>{singleDetails?.data?.model}</span></p>
                    <p>Pucc Upto<span>{singleDetails?.data?.puccUpto}</span></p>
                    <p>Insurer<span>{singleDetails?.data?.vehicleInsuranceCompanyName}</span></p>
                    <p>Vehicle Insurance Upto<span>{singleDetails?.data?.vehicleInsuranceUpto}</span></p>
                </div>
            </div>
        </>}  
    </>)
}